import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Hero from 'components/Layout/Hero';
import ContentBlock from 'components/Layout/ContentBlock';
import Levels from './sections/Levels';

const Amenities = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Hero
        title={t.amenities.hero.title}
        src={require('src/assets/images/amenities/hero.jpg')}
        fullStrapline={t.amenities.hero.fullStrapline}
      />
      <ContentBlock
        title={t.amenities.content1.title}
        text={t.amenities.content1.body}
        gallery={[
          {
            src: require('src/assets/images/amenities/gather/amenity_kitchen.jpg'),
            alt: 'Kitchen',
          },
          {
            src: require('src/assets/images/amenities/gather/amenity_lobby_fireplace.jpg'),
            alt: 'Fireplace',
          },
          {
            src: require('src/assets/images/amenities/gather/amenity_ping-pong.jpg'),
            alt: 'Ping Pong',
          },
        ]}
        portrait
      />
      <ContentBlock
        title={t.amenities.content2.title}
        text={t.amenities.content2.body}
        gallery={[
          {
            src: require('src/assets/images/amenities/sun/amenity_rooftop.jpg'),
            alt: 'Rooftop',
          },
          {
            src: require('src/assets/images/amenities/sun/amenity_exterior.jpg'),
            alt: 'Exterior',
          },
        ]}
        reverse
      />
      <Levels />
    </Root>
  );
};

export default Amenities;

const Root = styled.div``;
