import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import data from 'src/data/floorplans';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Table = (props) => {
  const { activePlan, handlePlan } = props;

  const { t, locale } = useIntl();

  return (
    <Root>
      <Header>
        <p className="col1">{t.floorplans.plan}</p>
        <p className="col2">{t.floorplans.type}</p>
        <p className="col3">{t.floorplans.size}</p>
      </Header>

      <Plans>
        {data.map((plan, i) => (
          <Plan active={activePlan === i && 1} key={i}>
            <Card active={activePlan === i && 1} onClick={() => handlePlan(i)}>
              <div className="col1 name">{plan.display}</div>

              <div className="details">
                <div className="col2">
                  <p>{locale === 'ko' ? plan.typeKO : plan.type}</p>
                </div>

                <div className="col3">
                  <p>
                    {plan.size}{' '}
                    <span className="mobile">{t.floorplans.size}</span>
                  </p>
                </div>
                {/* <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 1.65625L9 9.65625L0.999999 1.65625"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg> */}
              </div>
            </Card>

            <Container>
              <img
                src={require(`src/assets/images/floorplans/plans/${plan.id}.png`)}
                alt=""
                className="png"
              />

              <div>
                <img
                  src={require(`src/assets/images/floorplans/plates/${plan.id}.png`)}
                  alt=""
                  className="thumb"
                />

                <Button
                  text={t.floorplans.download}
                  href={`/pdfs/floorplans/${plan.id}.pdf`}
                />
              </div>
            </Container>
          </Plan>
        ))}
      </Plans>

      <Disclaimer className="small">{t.floorplans.disclaimer}</Disclaimer>
    </Root>
  );
};

export default Table;

Table.propTypes = {
  activePlan: PropTypes.number,
  handlePlan: PropTypes.func,
};

const Root = styled.div`
  margin: 0 auto;
  ${vw('width', 288, 648, 814)}
  ${vw('margin-top', 60, 188, 120)}
  ${vw('padding-bottom', 80, 120, 160)}

  .header {
  }
  .col1 {
    ${vw('width', 72, 107, 214)}
  }
  .col2 {
    ${vw('width', 'auto', 317, 403)}
  }
`;

const Header = styled.div`
  display: none;
  ${vw('padding-left', 0, 20)}

  p {
    text-transform: uppercase;
    ${vw('font-size', 16, 16, 17)}
    ${vw('line-height', 28, 28, 22)}
    ${vw('letter-spacing', 4, 4, 3)}
  }
  @media ${media.tablet} {
    display: flex;
  }
`;

const Plans = styled.div`
  border-top: 2px solid ${({ theme }) => theme.color.navy};
  border-bottom: 2px solid ${({ theme }) => theme.color.navy};
  ${vw('margin-top', 0, 12, 16)}
  @media ${media.tablet} {
    border-top: 3px solid ${({ theme }) => theme.color.navy};
    border-bottom: 3px solid ${({ theme }) => theme.color.navy};
  }
`;

const Plan = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gold};
  ${(props) =>
    props.active ? vw('max-height', 999) : vw('max-height', 84, 95, 89)}
  transition: all 0.4s ease;
  overflow: hidden;
  .name {
    font-family: ${({ theme }) => theme.font.header};
    text-transform: uppercase;
    ${vw('font-size', 26, 54)}
    ${vw('line-height', 30, 62)}
    ${vw('letter-spacing', 0.5, 1)}
  }

  .details {
    p {
      text-transform: uppercase;
      ${vw('font-size', 13, 16, 18)}
      ${vw('line-height', 21, 28)}
      ${vw('letter-spacing', 1, 4)}
    }
  }
  @media ${media.desktop} {
    .details p {
      font-size: ${vwDesktop(18)} !important;
    }
  }
`;

const Card = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  background-color: ${(props) =>
    props.active ? props.theme.color.navy : 'transparent'};
  color: ${(props) => (props.active ? 'white' : props.theme.color.navy)};
  transition: all 0.4s ease;
  ${vw('padding-left', 20, 20)}
  ${vw('padding-top', 20, 0)}
  ${vw('height', 83, 94, 88)}
  @media ${media.desktop} {
    &:hover {
      background-color: ${({ theme }) => theme.color.navy};
      color: white;
    }
  }

  .name {
    font-family: ${({ theme }) => theme.font.header};
    text-transform: uppercase;
    ${vw('font-size', 26, 54)}
    ${vw('line-height', 30, 62)}
    ${vw('letter-spacing', 0.5, 1)}
  }

  .details {
    p {
      text-transform: uppercase;
      ${vw('font-size', 13, 16)}
      ${vw('line-height', 21, 28)}
      ${vw('letter-spacing', 1, 4)}
    }
  }

  @media ${media.tablet} {
    align-items: center;
    .details {
      display: flex;
      align-items: center;
    }

    .mobile {
      display: none;
    }
  }

  svg {
    position: absolute;
    ${vw('top', 31, 44, 44)}
    ${vw('right', 20, 20, 20)}
    ${vw('width', 16, 22, 22)}
    ${vw('height', 8, 11, 11)}
    transform: ${(props) =>
      props.active
        ? 'translateY(-50%) rotate(180deg)'
        : 'translateY(-50%) rotate(0)'};
    transition: all 0.4s ease;
    path {
      stroke: ${(props) => (props.active ? 'white' : props.theme.color.navy)};
    }
  }
  @media ${media.desktop} {
    svg {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-top', 40, 40, 80)}
  ${vw('padding-bottom', 40, 40)}
  background-color: white;
  .png {
    ${vw('width', 288, 648, 670)}
    ${vw('margin-bottom', 40, 40, 80)}
  }

  .thumb {
    ${vw('width', 288, 296)}
    ${vw('margin-bottom', 40, 0)}
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${media.tablet} {
    > div {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      ${vw('padding-right', 0, 0, 72)}
      ${vw('padding-left', 0, 0, 72)}
    }
  }
`;

const Disclaimer = styled.div`
  ${vw('margin-top', 20, 35, 16)}
`;
