import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const Title = (props) => {
  const { text, small } = props;

  return (
    <Root small={small}>
      <h1 className="navy">{text}</h1>
    </Root>
  );
};

export default Title;

Title.propTypes = {
  text: PropTypes.string,
  small: PropTypes.bool,
};

const Root = styled.div`
  ${vw('padding-top', 132, 200, 240)}
  ${vw('padding-right', 16, 60, 48)}
  ${vw('padding-left', 16, 60, 48)}
  h1 {
    text-align: center;
    margin: 0 auto;

    ${(props) =>
      props.small &&
      css`
        ${vw('font-size', 26, 48)}
        ${vw('line-height', 30, 64)}
      ${vw('letter-spacing', 0.5, 1.5)}
      `}
  }
`;
