export default [
  {
    id: 'a',
    display: 'a',
    type: 'One Bedroom & Den',
    typeKO: '1 베드룸 + 덴',
    bed: 1,
    bath: 1,
    den: true,
    indoor: '646',
    outdoor: '75',
    size: '721',
    level: ['3-6', '8', '9-34'],
    viewLevel: '32',
  },
  // {
  //   id: 'a_805',
  //   display: 'a',
  //   type: 'One Bedroom & Den',
  //   typeKO: '1 베드룸 + 덴',
  //   unit: '805',
  //   bed: 1,
  //   den: true,
  //   bath: 1,
  //   indoor: '662',
  //   outdoor: '150',
  //   size: '812',
  //   level: ['8'],
  //   viewLevel: '08',
  // },
  {
    id: 'a1',
    display: 'a1',
    type: 'One Bedroom & Den',
    typeKO: '1 베드룸 + 덴',
    adaptable: true,
    bed: 1,
    bath: 1,
    den: true,
    indoor: '660',
    outdoor: '75',
    size: '735',
    level: ['9-34'],
    viewLevel: '32',
  },
  {
    id: 'b',
    display: 'b',
    type: 'Two Bedroom',
    typeKO: '2 베드룸',
    bed: 2,
    bath: 2,
    indoor: '757',
    outdoor: '180',
    size: '937',
    level: ['3-6', '7', '8', '9-34'],
    viewLevel: '32',
  },
  {
    id: 'b1',
    display: 'b1',
    bed: 2,
    bath: 2,
    type: 'Two Bedroom',
    typeKO: '2 베드룸',
    indoor: '853-861',
    outdoor: '118',
    size: '971-979',
    level: ['7', '8', '9-34'],
    viewLevel: '32',
  },
  // {
  //   id: 'b2',
  //   display: 'b2',
  //   type: 'Two Bedroom',
  //   typeKO: '2 베드룸',
  //   bed: 2,
  //   bath: 2,
  //   indoor: '757',
  //   outdoor: '118',
  //   size: '875',
  //   level: ['3-6'],
  // },
  {
    id: 'c',
    display: 'c',
    type: 'Two Bedroom',
    typeKO: '2 베드룸',
    adaptable: true,
    bed: 2,
    bath: 2,
    indoor: '838-850',
    outdoor: '134',
    size: '972-984',
    level: ['3-6', '7', '8', '9-34'],
    viewLevel: '32',
  },
  {
    id: 'c1',
    display: 'c1',
    bed: 2,
    bath: 2,
    type: 'Two Bedroom',
    typeKO: '2 베드룸',
    indoor: '807',
    outdoor: '134',
    size: '941',
    level: ['9-34'],
    viewLevel: '32',
  },
  // {
  //   id: 'ph1',
  //   display: 'ph1',
  //   bed: 3,
  //   bath: 2,
  //   type: 'Three Bedroom',
  //   typeKO: '3 베드룸',
  //   indoor: '1,016',
  //   outdoor: '984',
  //   size: '2,000',
  //   level: ['35'],
  // },
  // {
  //   id: 'ph2',
  //   display: 'ph2',
  //   bed: 3,
  //   bath: 2,
  //   type: 'Three Bedroom',
  //   typeKO: '3 베드룸',
  //   indoor: '1,048',
  //   outdoor: '984',
  //   size: '2,032',
  //   level: ['35'],
  // },
  // {
  //   id: 'ph3',
  //   display: 'ph3',
  //   bed: 3,
  //   bath: 2,
  //   type: 'Three Bedroom',
  //   typeKO: '3 베드룸',
  //   indoor: '1,087',
  //   outdoor: '1,218',
  //   size: '2,305',
  //   level: ['35'],
  // },
  // {
  //   id: 'th1',
  //   display: 'th1',
  //   bed: 2,
  //   bath: 2,
  //   type: 'Two Bedroom',
  //   typeKO: '2 베드룸',
  //   indoor: '1,063',
  //   outdoor: '286',
  //   size: '1,349',
  //   level: ['1', '2'],
  // },
  // {
  //   id: 'th2',
  //   display: 'th2',
  //   bed: 3,
  //   bath: 2,
  //   type: 'Three Bedroom',
  //   typeKO: '3 베드룸',
  //   indoor: '1,260',
  //   outdoor: '396',
  //   size: '1,656',
  //   level: ['1', '2'],
  // },
];
