import React from 'react';

import theme from 'src/styles/theme';

const ArrowLeft = (props) => {
  const {
    className,
    style,
    onClick,
    white,
    navy,
  } = props;

  return (
    <svg
      className={className}
      onClick={onClick}
      style={style}
      width="12px"
      height="24px"
      viewBox="0 0 12 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon/carousel-arrow-left" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon id="Path" fill={white ? '#fff' : navy ? theme.color.navy : theme.color.gold} fill-rule="nonzero" points="12 0.882586601 10.8507887 0 0 12 10.8507887 24 12 23.1174134 1.94799152 12"></polygon>
      </g>
    </svg>
  );
};

export default ArrowLeft;
