import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import OpalOnyx from './OpalOnyx';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const OpalOnyxBlock = (props) => {
  const { half, full, opal, onyx, title, body } = props;

  return (
    <Root>
      <Container half={half} full={full}>
        <OpalOnyx opal={opal} onyx={onyx} />
      </Container>

      {title && body && (
        <Body className="navy">
          <h2>{title}</h2>
          <p>{body}</p>
        </Body>
      )}
    </Root>
  );
};

export default OpalOnyxBlock;

OpalOnyxBlock.propTypes = {
  half: PropTypes.bool,
  full: PropTypes.bool,
  opal: PropTypes.string,
  onyx: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
};

const Full = css`
  ${vw('width', 288, 648, 1344)}
`;

const Half = css`
  ${vw('width', 288, 648, 600)}
`;

const Root = styled.div`
  ${vw('margin-top', 80, 120, 160)}
  @media ${media.desktop} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  ${(props) => props.full && Full}
  ${(props) => props.half && Half}
`;

const Body = styled.div`
  display: none;
  @media ${media.tablet} {
    display: block;
  }
  margin: 0 auto;
  h2 {
    ${vw('margin-bottom', 20, 40)}
  }
  ${vw('width', 288, 648, 470)}
  ${vw('margin-top', 40, 40, 0)}
  ${vw('margin-left', 'auto', 'auto', 85)}
`;
