import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useIntl from 'components/useIntl';

import vw from 'src/styles/utils';

const Select = (props) => {
  const { label, name, id, required, options } = props;

  const { t } = useIntl();

  const [value, setValue] = useState('');

  return (
    <Root>
      <label htmlFor={id} className="form label">
        <p>{label}</p>
      </label>
      <select
        name={name}
        id={id}
        required={required}
        className={`form input ${value === '' && 'inactive'}`}
        placeholder="Select One"
        onChange={(e) => setValue(e.target.value)}
      >
        <option value="">{t.register.select}</option>
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <div>
        <img
          src={require('src/assets/images/_global/icons/select-down.svg')}
          alt="Select"
        />
      </div>
    </Root>
  );
};

Select.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array,
};

const Root = styled.div`
  width: 100%;
  position: relative;
  ${vw('margin-bottom', 20, 24)}
  label {
    color: ${({ theme }) => theme.color.navy};
    display: block;
    ${vw('margin-bottom', 4)}
  }
  select {
    width: 100%;
    background-color: transparent;
    color: ${({ theme }) => theme.color.navy};
    position: relative;
    appearance: none;
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.color.navy};
    transition: ${({ theme }) => theme.transition};
    ${vw('height', 38, 40)}
    ${vw('padding-top', 9, 10)}
    ${vw('padding-right', 12)}
    ${vw('padding-left', 12)}
    ${vw('padding-bottom', 9, 10)}
    &:focus {
      outline: none;
      background-color: white;
    }
  }
  div {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    ${vw('width', 38, 40)}
    ${vw('height', 38, 40)}
    img {
      display: block;
      ${vw('width', 12)}
    }
  }
`;

export default Select;
