import React, { useState } from 'react';
import styled from 'styled-components';

import Nav from './elements/Nav';
import Menu from './elements/Menu';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Root>
      <Nav isOpen={isOpen} setIsOpen={setIsOpen} />
      <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
    </Root>
  );
};

export default Header;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.navy};
`;
