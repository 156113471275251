import React, { useState } from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Title from 'components/Layout/Title';
import Table from './elements/Table';
import Views from './sections/Views';

import data from 'src/data/floorplans';

import vw from 'src/styles/utils';

const Floorplans = () => {
  const [activePlan, setActivePlan] = useState(null);

  const { t } = useIntl();

  const handlePlan = (i) => {
    if (activePlan === i) {
      setActivePlan(null);
    } else {
      setActivePlan(i);
    }
  };

  return (
    <Root>
      <Title text={t.floorplans.title} small />
      <Table activePlan={activePlan} handlePlan={handlePlan} />
      <Views activePlan={data[activePlan]} />
      <Spacing>
        <span />
      </Spacing>
    </Root>
  );
};

export default Floorplans;

const Root = styled.div`
  background-color: #fcfbf8;
`;

const Spacing = styled.div`
  position: relative;
  display: block;
  ${vw('height', 80, 80)}
  span {
    width: 90%;
    height: 0.5px;
    background-color: ${({ theme }) => theme.color.gold};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
