export default [
  {
    title: 'Restaurants',
    list: [
      {
        name: 'Trattoria',
        top: '19.74%',
        left: '46.19%',
      },
      {
        name: 'Earls',
        top: '30.99%',
        left: '48.77%',
      },
      {
        name: 'Cactus Club',
        top: '37.82%',
        left: '47.42%',
      },
      {
        name: 'Chipotle',
        top: '47.97%',
        left: '55.89%',
      },
      {
        name: 'Mon Paris Patisserie',
        top: '44.09%',
        left: '40.04%',
      },
      {
        name: 'Sushi Garden',
        top: '24.72%',
        left: '52.70%',
      },
      {
        name: 'Marutama Ramen',
        top: '51.84%',
        left: '81.69%',
      },
      {
        name: "Nando's",
        top: '45.75%',
        left: '64.61%',
      },
      {
        name: 'Starbucks',
        top: '33.39%',
        left: '64.98%',
      },
      {
        name: 'La Forêt',
        top: '69.00%',
        left: '64.12%',
      },
      {
        name: 'Fondway Cafe',
        top: '55.53%',
        left: '50.36%',
      },
      {
        name: 'Jenjudan',
        top: '11.99%',
        left: '2.45%',
      },
      {
        name: 'Boiling Point',
        top: '56.27%',
        left: '87.46%',
      },
      {
        name: 'ZUBU',
        top: '52.58%',
        left: '56.26%',
      },
      {
        name: 'Bella Gelateria',
        top: '44.83%',
        left: '58.35%',
      },
      {
        name: 'The Dolar Shop',
        top: '35.05%',
        left: '45.08%',
      },
    ]
  },
  {
    title: 'Recreation',
    list: [
      {
        name: 'Bonsor Recreation Complex',
        top: '55.53%',
        left: '62.89%',
      },
      {
        name: 'Cineplex Cinemas Metropolis',
        top: '35.97%',
        left: '60.44%',
      },
      {
        name: 'Central Park',
        top: '39.66%',
        left: '15.23%',
      },
      {
        name: 'Central Park Public Tennis Courts',
        top: '32.28%',
        left: '22.97%',
      },
      {
        name: 'Central Park Pitch + Putt',
        top: '61.99%',
        left: '14.74%',
      },
      {
        name: 'Bonsor Park',
        top: '60.88%',
        left: '65.35%',
      },
      {
        name: 'Kinnee Park',
        top: '49.44%',
        left: '27.88%',
      },
      {
        name: 'Maywood Park',
        top: '58.30%',
        left: '34.27%',
      },
      {
        name: 'GoodLife Fitness',
        top: '20.84%',
        left: '47.54%',
      },
      {
        name: 'Fitness World',
        top: '57.38%',
        left: '89.06%',
      },
      {
        name: 'Anytime Fitness',
        top: '84.68%',
        left: '81.69%',
      },
      {
        name: 'David Gray Off-Leash Park',
        top: '84.68%',
        left: '35.50%',
      },
    ]
  },
  {
    title: 'Shopping & Services',
    list: [
      {
        name: 'Metropolis at Metrotown',
        top: '37.82%',
        left: '54.66%',
      },
      {
        name: 'Crystal Mall',
        top: '26.56%',
        left: '44.34%',
      },
      {
        name: "Hudson's Bay",
        top: '43.91%',
        left: '66.58%',
      },
      {
        name: 'ICBC',
        top: '45.94%',
        left: '62.89%',
      },
      {
        name: 'Pharmasave',
        top: '43.91%',
        left: '38.94%',
      },
      {
        name: 'PriceSmart Foods',
        top: '29.33%',
        left: '50.73%',
      },
      {
        name: 'RBC',
        top: '19.37%',
        left: '37.71%',
      },
      {
        name: 'Real Canadian Superstore',
        top: '33.39%',
        left: '52.82%',
      },
      {
        name: 'Walmart',
        top: '46.67%',
        left: '53.80%',
      },
      {
        name: 'Save-On-Foods',
        top: '14.94%',
        left: '44.34%',
      },
      {
        name: 'Rexall',
        top: '40.22%',
        left: '45.08%',
      },
      {
        name: 'Scotiabank',
        top: '13.09%',
        left: '36.48%',
      },
      {
        name: 'Shoppers Drug Mart',
        top: '32.65%',
        left: '64.12%',
      },
      {
        name: 'T&T Supermarket',
        top: '44.46%',
        left: '50.98%',
      },
      {
        name: 'TD Bank',
        top: '27.30%',
        left: '48.28%',
      },
      {
        name: 'HSBC',
        top: '23.06%',
        left: '45.82%',
      },
      {
        name: 'Vancity',
        top: '37.82%',
        left: '71.74%',
      },
      {
        name: 'Hilton Burnaby',
        top: '30.25%',
        left: '45.82%',
      },
      {
        name: 'Best Buy',
        top: '35.23%',
        left: '45.08%',
      },
      {
        name: 'BC Liquor Store',
        top: '13.09%',
        left: '41.40%',
      },
      {
        name: 'London Drugs',
        top: '40.77%',
        left: '69.28%',
      },
      {
        name: 'Burnaby Public Library',
        top: '34.50%',
        left: '40.66%',
      },
      {
        name: 'WeWork',
        top: '33.39%',
        left: '48.28%',
      },
    ]
  },
  {
    title: 'Schools',
    list: [
      {
        name: 'Marlborough Elementary',
        top: '28.41%',
        left: '72.60%',
      },
      {
        name: 'Maywood Community School',
        top: '61.25%',
        left: '54.66%',
      },
      {
        name: 'Burnaby South Secondary',
        top: '91.51%',
        left: '95.82%',
      },
      {
        name: 'South Slope Elementary',
        top: '84.68%',
        left: '42.50%',
      },
      {
        name: 'Wonderworld Montessori Academy',
        top: '45.20%',
        left: '41.03%',
      },
      {
        name: 'CEFA Early Learning',
        top: '4.98%',
        left: '19.16%',
      },
      {
        name: 'Suncrest Elementary',
        top: '91.51%',
        left: '19.16%',
      },
      {
        name: 'Nelson Elementary',
        top: '91.51%',
        left: '63.51%',
      },
    ]
  },
]