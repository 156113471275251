import React, { useState } from 'react';
import styled from 'styled-components';

import Map from '../elements/Map';
import List from '../elements/List';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const MapSection = () => {
  const [activeCategory, setActiveCategory] = useState(0);
  const [activePoint, setActivePoint] = useState(null);

  const handleCategory = (i) => {
    setActivePoint(null);
    if (activeCategory === i) {
      setActiveCategory(null);
    } else {
      setActiveCategory(i);
    }
  }

  const handlePoint = (i) => {
    if(activePoint === i) {
      setActivePoint(null);
    } else {
      setActivePoint(i);
    }
  }

  return (
    <Root>
      <Map 
        activeCategory={activeCategory}
        activePoint={activePoint}
        handlePoint={handlePoint}
      />
      <List
        activeCategory={activeCategory}
        handleCategory={handleCategory}
        activePoint={activePoint}
        handlePoint={handlePoint}
      />
    </Root>
  )
}

export default MapSection;

const Root = styled.div`
  ${vw('margin-top', 80, 120, 160)}
  @media ${media.desktop} {
    display: flex;
    justify-content: center;
  }
`;