import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useIntl from 'components/useIntl';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Map = (props) => {
  const { activeCategory, activePoint, handlePoint } = props;

  const { t } = useIntl();

  return (
    <Root>
      <img src={require('src/assets/images/neighbourhood/base_map.png')} alt='map' />

      {activeCategory !== null && 
        t.neighbourhood.map[activeCategory].list.map((point, i) => (
          <Callout
            key={i}
            active={activePoint === i}
            top={point.top}
            left={point.left}
            onClick={() => handlePoint(i)}
          >
            <Circle>
              {i + 1}
            </Circle>

            <Text
              style={{
                opacity: activePoint === i ? '1' : '0',
                pointerEvents: activePoint === i ? 'auto' : 'none',
              }}
            >
              {point.name}
            </Text>
          </Callout>
      ))}
    </Root>
  )
}

export default Map;

Map.propTypes = {
  activeCategory: PropTypes.number,
  activePoint: PropTypes.number,
  handlePoint: PropTypes.func,
}

const Root = styled.div`
  position: relative;
  ${vw('margin-right', 0, 30, 0)}
  ${vw('margin-left', 0, 30, 0)}
  ${vw('margin-bottom', 40, 40, 0)}
  img {
    ${vw('width', 320, 708, 814)}
    ${vw('height', 214, 472, 542)}
  }
`;

const Callout = styled.div`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  transform: translate(-50%, -50%);
  z-index: ${props => props.active ? '1' : '0'};
`;

const Circle = styled.div`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family};
  font-weight: 600;
  background-color: ${({ theme }) => theme.color.navy};
  line-height: normal;
  color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${vw('font-size', 10)}
  ${vw('width', 15, 20)}
  ${vw('height', 15, 20)}
`;

const Text = styled.div`
  display: none;
  @media ${media.tablet} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.color.navy};
    font-family: ${({ theme }) => theme.font.family};
    color: white;
    white-space: nowrap;
    transition: all 0.4s ease;
    ${vw('font-size', 10, 10)}
    ${vw('letter-spacing', 0.72, 0.72)}
    ${vw('height', 28, 28)}
    ${vw('padding-right', 7, 7)}
    ${vw('padding-left', 7, 7)}
    ${vw('bottom', 28, 28)}

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 0; 
      height: 0; 
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid ${({ theme }) => theme.color.navy};
      ${vw('bottom', -7)}
    }
  }
`;