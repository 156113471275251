import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';
import { Link } from 'react-router-dom';

import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Project = () => {
  const { t, getLocaleURL } = useIntl();

  return (
    <Root>
      <Logo to={`${getLocaleURL()}`}>
        <img
          src={require('src/assets/images/_global/logos/logo_full_white.svg')}
          alt="Riviera logo"
        />
      </Logo>

      <Container>
        <p className="title uppercase">{t['footer'].column1.title}</p>

        <p className="body">
          <a className="footer-link" href="tel:778.658.0288">
            778.658.0288
          </a>
          <a className="footer-link" href="mailto:info@rivierabyledmac.com">
            info@rivierabyledmac.com
          </a>
        </p>

        <Button
          to={`${getLocaleURL()}/register`}
          text={t['registerNow']}
          gold
        />
      </Container>

      <Container>
        <p className="title uppercase">{t['footer'].column2.title}</p>

        <p>
          <a
            className="footer-link"
            href="https://goo.gl/maps/biZi3oH7LxTbGZUb9"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t['footer'].column2.text}
          </a>
        </p>
        <br />
        <br />
        <Button
          href="https://app.acuityscheduling.com/schedule.php?owner=20816987&location=4700%20Imperial%20Street%2C%20Burnaby%2C%20BC"
          text={t['register']}
          gold
        />
      </Container>

      <Container>
        <p className="title uppercase">{t['footer'].column3.title}</p>

        <Map
          href="https://goo.gl/maps/biZi3oH7LxTbGZUb9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require('src/assets/images/_global/map@3x.png')}
            alt="map"
          />
        </Map>
      </Container>
    </Root>
  );
};

export default Project;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.navy};
  ${vw('padding-top', 40, 60, 50)}
  ${vw('padding-right', 0, 0, 100)}
  ${vw('padding-bottom', 40, 60, 50)}
  ${vw('padding-left', 0, 0, 100)}
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
const Logo = styled(Link)`
  ${vw('margin-bottom', 40, 60)}
  img {
    ${vw('width', 198, 242)}
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${vw('margin-bottom', 20, 40)}
  p {
    display: block;
    color: white;

    a {
      display: block;
      color: white;
    }
  }
  .title {
    font-weight: 600;
    font-family: 'Montserrat';
    ${vw('font-size', 12, 14, 14)}
    ${vw('line-height', 16, 21, 21)}
    ${vw('letter-spacing', 2, 2, 1.5)}

    ${vw('margin-bottom', 8, 10, 12)}
  }
  .body {
    ${vw('margin-bottom', 20, 40)}
  }
  .footer-link:hover {
    color: ${({ theme }) => theme.color.gold};
    transition: all 0.5s ease;
  }
`;

const Map = styled.a`
  display: block;
  img {
    ${vw('width', 160, 200)}
  }
`;
