import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import useIntl from 'components/useIntl';

// Icons
import ArrowLeft from 'components/Icons/ArrowLeft';
import ArrowRight from 'components/Icons/ArrowRight';
import Button from 'components/Button';

// Slick styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = () => {
  const { t, getLocaleURL } = useIntl();

  const [start, setStart] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);

  const hasLoaded = useSelector((state) => state.load.hasLoaded);

  useEffect(() => {
    if (hasLoaded) {
      setTimeout(() => setStart(true), 2300);
    }
  }, [hasLoaded]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <ArrowRight white />,
    prevArrow: <ArrowLeft white />,
    fade: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (current) => setCurrentSlide(current + 1),
  };
  return (
    <Root>
      <SliderContainer
        style={
          {
            // opacity: start ? 1 : 0,
            // transform: start ? 'translateY(0)' : 'translateY(10%)',
            // transition: '0.7s ease'
          }
        }
      >
        <Slider {...settings}>
          <ImageWrapper>
            <Pattern
              src={require('src/assets/images/home/graphic_pattern.svg')}
              alt="pattern"
              className="left"
            />
            <Pattern
              src={require('src/assets/images/home/graphic_pattern.svg')}
              alt="pattern"
              className="right"
            />
            <Image
              src={require('src/assets/images/home/hero_building_rendering.png')}
              width={544}
              height={740}
            />
          </ImageWrapper>
          <ImageWrapper wide>
            <Image
              src={require('src/assets/images/home/hero_entrance_rendering.jpg')}
              width={1238}
              height={740}
            />
          </ImageWrapper>
        </Slider>
        <Pagination>{currentSlide} / 2</Pagination>
      </SliderContainer>

      <Wrapper>
        <p className="title intro home">{t.home.hero.title}</p>
        <Body>
          <p className="body">{t.home.hero.text}</p>
        </Body>
      </Wrapper>

      <StyledButton
        to={`${getLocaleURL()}/floorplans`}
        text={t.home.hero.button}
        gold
      />
    </Root>
  );
};

const Root = styled.div`
  text-align: center;
  margin: 0 ${vwMobile(16)};
  ${vw('padding-bottom', 40, 120, 92)}

  @media ${media.desktop} {
    margin-top: ${vwDesktop(100)};
  }
`;

const Wrapper = styled.div`
  margin-top: ${vwMobile(35)};
  margin-bottom: ${vwMobile(20)};
  display: flex;
  flex-direction: column;
  text-align: center;
  p {
    color: white;
  }

  .title {
    font-family: ${({ theme }) => theme.font.header};
    font-weight: 400;
    ${vw('font-size', 18, 26)}
    ${vw('line-height', 26, 40)}
    ${vw('letter-spacing', 0.3, 0.5)}
  }

  .body {
    margin: auto;
  }

  @media ${media.tablet} {
    margin-top: ${vwTablet(35)};
    margin-bottom: ${vwTablet(40)};
    margin-left: ${vwTablet(60)};
    margin-right: ${vwTablet(60)};

    .body {
      width: ${vwTablet(419)};
    }
    .intro {
      width: ${vwTablet(580)};
    }
  }

  @media ${media.desktop} {
    margin: ${vwDesktop(72)} auto;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    max-width: ${vwDesktop(1062)};

    .title {
      width: ${vwDesktop(460)};
    }

    .body {
      width: ${vwDesktop(390)};
    }
  }
`;

const SliderContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${vw('margin-top', 37, 28)}

  height: ${vwMobile(392)};
  width: ${vwMobile(288)};

  @media ${media.tablet} {
    height: ${vwTablet(888)};
    width: ${vwTablet(652)};
  }

  @media ${media.desktop} {
    width: auto;
    height: ${vwDesktop(740)};
  }

  .slick-slider {
    margin-right: -3px;

    @media ${media.desktop} {
      height: ${vwDesktop(740)};
    }
  }

  .slick-arrow {
    position: absolute;
    ${vw('bottom', 18, 23, 27)};
    top: unset;
    z-index: 1;
  }

  .slick-prev {
    ${vw('left', 94, 255, 568)};
  }

  .slick-next {
    ${vw('right', 94, 255, 568)};
  }
`;

const Body = styled.div`
  position: relative;

  &::before {
    content: '';
    background-color: ${({ theme }) => theme.color.gold};
    height: 1px;
    width: ${vwTablet(120)};
    margin-left: auto;
    margin-right: auto;
    ${vw('margin-top', 10, 40, 0)}
    ${vw('margin-bottom', 20, 40, 0)}
    display: block;

    @media ${media.desktop} {
      top: 0;
      position: absolute;
      width: 100%;
    }
  }

  @media ${media.desktop} {
    display: flex;
    align-items: flex-end;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;

const ImageWrapper = styled.div`
  margin-left: -2px;

  height: ${vwMobile(392)};
  width: ${vwMobile(288)};

  @media ${media.tablet} {
    height: ${vwTablet(888)};
    width: ${vwTablet(652)};
  }

  @media ${media.desktop} {
    height: ${vwDesktop(740)};
    width: auto;
  }
`;

const Pattern = styled.div`
  display: none;

  @media ${media.desktop} {
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
    position: absolute;
    ${vw('width', 118)}
    ${vw('height', 484)}

    &.left {
      ${vw('left', 244)}
    }
    &.right {
      ${vw('right', 244)}
    }
  }
`;

const Image = styled.img`
  display: block;
  margin-top: ${vwMobile(0)};
  object-fit: contain;
  object-position: 85%;
  transition: 0.7s ease;
  width: 100%;
  height: 100%;

  @media ${media.desktop} {
    margin: auto;
    width: auto;
    height: ${vwDesktop(740)};
  }
`;

const Pagination = styled.div`
  background-color: white;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 50%;
  color: ${({ theme }) => theme.color.navy};
  font-weight: 600;
  ${vw('width', 30, 46)}
  ${vw('height', 30, 46)}
  ${vw('font-size', 8, 11)}
  ${vw('bottom', 14, 16, 28)};
`;

export default Hero;
