import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Title from 'components/Layout/Title';
import Form from './elements/Form';

import vw from 'src/styles/utils';

const Register = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Title text={t.registerNow} />
      <Wrapper>
        <Form />
      </Wrapper>
    </Root>
  );
};

export default Register;

const Root = styled.div`
  ${vw('padding-bottom', 80, 120, 160)}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  ${vw('margin-top', 28, 40, 80)}
  ${vw('padding-right', 16, 60, 80)}
  ${vw('padding-left', 16, 60, 80)}
`;
