import React from 'react';
import styled from 'styled-components';

import CloseIcon from 'src/assets/images/_global/icons/close.svg';
import data from 'src/data/amenities';

import vw from 'src/styles/utils';

const Modal = (props) => {
  const { activeLevel, activePoint, handleClose } = props;

  return (
    <Root
      style={{
        opacity: activePoint !== null ? '1' : '0',
        pointerEvents: activePoint !== null ? 'auto' : 'none',
      }}
    >
      {activePoint !== null && (
        <>
          <h4>{data.levels[activeLevel].text}</h4>
          <Close onClick={handleClose} src={CloseIcon} width="20" height="20" />
          <Img src={data.levels[activeLevel].poi[activePoint].src} alt="" />
          <p>{data.levels[activeLevel].poi[activePoint].title}</p>
        </>
      )}
    </Root>
  );
};

export default Modal;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  transition: all 0.4s ease;
  z-index: 99;
  h4 {
    position: absolute;
    font-family: ${({ theme }) => theme.font.family};
    font-weight: 400;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.navy};
    left: 50%;
    transform: translateX(-50%);
    ${vw('font-size', 14)}
    ${vw('line-height', 22)}
    ${vw('letter-spacing', 3)}
    ${vw('top', 80, 120, 60)}
  }
  p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: ${({ theme }) => theme.color.navy};
    ${vw('bottom', 80, 120, 46)}
  }
`;

const Img = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 2px 12px rgba(75, 78, 82, 0.4008));
  object-fit: cover;
  ${vw('width', 256, 'auto')}
  ${vw('height', 'auto', 648, 560)}
`;

const Close = styled.img`
  cursor: pointer;
  position: absolute;
  ${vw('width', 20, 24)}
  ${vw('top', 20, 24)}
  ${vw('top', 20, 36)}
  ${vw('right', 20, 36)}
`;
