import React from 'react';

import theme from 'src/styles/theme';

const ArrowRight= (props) => {
  const { className, style, onClick, white, navy } = props;

  return (
    <svg 
      className={className}
      onClick={onClick}
      style={style}
      {...props}
      width="12px" height="24px" viewBox="0 0 12 24"
      fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon/carousel-arrow-right" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Path" fill={white ? '#fff' : navy ? theme.color.navy : theme.color.gold} fill-rule="nonzero" points="5.27354897e-16 0.882586601 1.14921129 0 12 12 1.14921129 24 -8.34135577e-16 23.1174134 10.0520085 12"></polygon>
      </g>
    </svg>
  );
};

export default ArrowRight;
