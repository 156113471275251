import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Title from 'components/Layout/Title';
import Button from 'components/Button';

import vw from 'src/styles/utils';

const ThankYou = () => {
  const { t, getLocaleURL } = useIntl();

  return (
    <Root>
      <Title text={t.thankYou.title} />
      <p>{t.thankYou.body}</p>
      <Button text={t.thankYou.button} to={`${getLocaleURL()}/`} />
    </Root>
  );
};

export default ThankYou;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-bottom', 80, 120, 160)}
  p {
    text-align: center;
    ${vw('margin-top', 24)}
    ${vw('margin-bottom', 40)}
  }
`;
