import React from 'react';
import styled from 'styled-components';

import Project from './sections/Project';
import Corporate from './sections/Corporate';

const Footer = () => {
  return (
    <Root>
      <Project />
      <Corporate />
    </Root>
  )
}

export default Footer;

const Root = styled.div``;