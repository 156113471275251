import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import useIntl from 'components/useIntl';

import media from 'src/styles/media';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';

const social = [
  {
    icon: require('src/assets/images/_global/icons/icon-weixin.png'),
    wechat: true,
    text: 'Weixin',
  },
  {
    icon: require('src/assets/images/_global/icons/icon-facebook.png'),
    to: 'https://www.facebook.com/Ledmachomes/',
    text: 'Facebook',
  },
  {
    icon: require('src/assets/images/_global/icons/icon-twitter.png'),
    to: 'https://twitter.com/LedMacHomes',
    text: 'Twitter',
  },
  {
    icon: require('src/assets/images/_global/icons/icon-youtube.png'),
    to: 'https://www.youtube.com/channel/UCwdwI3hjms5uhcsERXL2bIg',
    text: 'Youtube',
  },
  {
    icon: require('src/assets/images/_global/icons/icon-instagram.png'),
    to: 'https://www.instagram.com/ledmachomes/',
    text: 'Instagram',
  },
  {
    icon: require('src/assets/images/_global/icons/icon-linkedin.png'),
    to: 'https://www.linkedin.com/company/ledingham-mcallister/ ',
    text: 'LinkedIn',
  },
];

const Corporate = (props) => {
  const [isWechatOpen, setIsWechatOpen] = useState(false);

  const { t, getLocaleURL } = useIntl();
  const today = new Date();

  return (
    <Root>
      <Wrapper>
        <div>
          <h3 className="ledmac-footer subheader navy">
            {t.footer.corporate.title}
          </h3>
          <p className="ledmac-footer small navy">{t.footer.corporate.text}</p>
          <Social>
            {social.map((item, index) =>
              item.wechat ? (
                <div onClick={() => setIsWechatOpen(true)} key={index}>
                  <img src={item.icon} alt={item.text} />
                </div>
              ) : (
                <a
                  href={item.to}
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={item.icon} alt={item.text} />
                </a>
              )
            )}
          </Social>
        </div>
        <a
          href="https://ledmac.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="logo"
        >
          <Logo
            src={require('src/assets/images/_global/logos/ledmac-logo.svg')}
            alt="Ledingham McAllister"
          />
        </a>
      </Wrapper>
      <Container>
        <p className="ledmac-footer disclaimer navy">{t.footer.disclaimer}</p>
        <Links>
          <p className="ledmac-footer link navy">
            © {today.getFullYear()} Ledingham McAllister.{' '}
            <br className="show-on-mobile" />
            all rights reserved
          </p>
          <span />
          <Link
            className="ledmac-footer link navy"
            to={`${getLocaleURL()}/privacy`}
          >
            {t.footer.corporate.privacy}
          </Link>
          <span />
          <a
            className="ledmac-footer link navy"
            href="https://www.bamdigital.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Website by Bam Digital
          </a>
        </Links>
        <WeChat
          style={{
            opacity: isWechatOpen ? 1 : 0,
            pointerEvents: isWechatOpen ? 'all' : 'none',
          }}
        >
          <div>
            <img
              src={require('src/assets/images/_global/icons/Ledmac_Wechat_Qr_Code_Banner.jpeg')}
              alt=""
              className="banner"
            />
            <img
              src={require('src/assets/images/_global/icons/wechat-close-icon.png')}
              alt=""
              className="close"
              onClick={() => setIsWechatOpen(false)}
            />
          </div>
        </WeChat>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  padding-top: ${vwMobile(40)};
  background-color: #fcfbf8;
  .disclaimer {
    margin: 0 0 ${vwMobile(20)};
    opacity: 0.7;
    text-transform: none;
    text-align: center;
    ${vw('font-size', 10)};
    ${vw('line-height', 16)}
    ${vw('letter-spacing', 0.1)}
  }
  @media ${media.tablet} {
    padding-bottom: 0;
    padding-top: ${vwTablet(60)};
    .disclaimer {
      margin: 0 0 ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    padding-top: ${vwDesktop(40)};
    .disclaimer {
      width: ${vwDesktop(997)};
      margin: 0 0 ${vwDesktop(16)};
      text-align: left;
    }
  }
`;

const Container = styled.div`
  padding: ${vwMobile(20)} ${vwMobile(10)} ${vwMobile(88)};
  background-color: white;

  @media ${media.tablet} {
    padding: ${vwTablet(30)} ${vwTablet(60)} ${vwTablet(99)};
  }

  @media ${media.desktop} {
    padding: ${vwDesktop(18)} ${vwDesktop(60)};
    text-align: left;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${vwMobile(16)} ${vwMobile(15)};
  > div {
    order: 2;
  }
  .logo {
    order: 1;

    img {
      ${vw('width', 193, 258)}
      ${vw('height', 90, 120)}
    }
  }

  .subheader {
    font-family: ${({ theme }) => theme.font.family};
    ${vw('font-weight', '500', '600')}
    ${vw('font-size', 13, 15)}
    ${vw('line-height', 18, 22)}
    ${vw('letter-spacing', 0.25, 0.25)}
  }

  p {
    margin: ${vwMobile(6)} 0 ${vwMobile(22)};
  }
  @media ${media.tablet} {
    padding: 0 ${vwTablet(60)};
    p {
      margin: ${vwTablet(4)} 0 ${vwTablet(24)};
    }
  }
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    padding: 0 ${vwDesktop(60)};
    > div {
      order: 1;
    }
    .logo {
      order: 2;
    }
    p {
      max-width: ${vwDesktop(965)};
      margin: ${vwDesktop(4)} 0 ${vwDesktop(24)};
    }
  }
  ${vw('padding-bottom', 32, 60, 30)};
`;

const Logo = styled.img`
  display: block;
  ${vw('width', 170, 170)}
  ${vw('margin-bottom', 40, 60, 0)}
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p,
  a {
    font-family: ${({ theme }) => theme.font.family};
    font-weight: 400;
    text-transform: uppercase;
    ${vw('font-size', 12, 12, 11)}
    ${vw('line-height', 16, 16, 16)}
    ${vw('letter-spacing', 1, 1, 1)}
  }
  a {
    text-decoration: underline;
  }

  p,
  a:first-of-type {
    margin-bottom: ${vwMobile(12)};
  }

  span {
    display: none;
  }

  @media ${media.tablet} {
    p,
    a:first-of-type {
      margin-bottom: ${vwTablet(12)};
    }
    br {
      display: none;
    }
  }

  @media ${media.desktop} {
    width: auto;
    flex-direction: row;
    align-items: unset;
    span {
      display: block;
      width: 1px;
      height: ${vwDesktop(12)};
      margin: 0 ${vwDesktop(14)};
    }
    p,
    a:first-of-type {
      margin-bottom: 0;
    }
    a {
      text-decoration: none;
      transition: all 0.4s ease;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${vwMobile(240)};
  margin: 0 auto;
  a,
  div {
    height: ${vwMobile(20)};
    width: ${vwMobile(20)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
  @media ${media.tablet} {
    width: ${vwTablet(240)};
    a,
    div {
      height: ${vwTablet(20)};
      width: ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    margin: 0;
    width: ${vwDesktop(240)};
    a,
    div {
      height: ${vwDesktop(20)};
      width: ${vwDesktop(20)};
      transition: all 0.4s ease;
      cursor: pointer;
    }
    a:hover {
      transition: 0.3s ease-in-out;
      opacity: 0.8;
    }
  }
`;

const WeChat = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
  z-index: 20;
  > div {
    width: ${vwMobile(300)};
    height: ${vwMobile(113)};
    position: relative;
    background-color: white;
    ${vw('width', 300, 500)}
    ${vw('height', 113, 188)}
  }
  .banner {
    height: 100%;
    width: auto;
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    ${vw('width', 20, 32)}
    ${vw('height', 20, 32)}
  }
`;

export default Corporate;
