import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Hero from 'components/Layout/Hero';
import OpalOnyxBlock from './elements/OpalOnyxBlock';
import Features from './sections/Features';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Interiors = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Hero
        title={t.interiors.hero.title}
        src={require('src/assets/images/interiors/hero.jpg')}
        fullStrapline={t.interiors.hero.fullStrapline}
      />

      <OpalOnyxBlock
        full
        opal={require('src/assets/images/interiors/kitchen-living_light.jpg')}
        onyx={require('src/assets/images/interiors/kitchen-living_dark.jpg')}
      />
      <ShowOnMobile>
        <Body>
          <h2>{t.interiors.opalOnyx.content2.title}</h2>
          <p>{t.interiors.opalOnyx.content2.body}</p>
        </Body>
      </ShowOnMobile>
      <OpalOnyxBlock
        half
        opal={require('src/assets/images/interiors/ensuite_light.jpg')}
        onyx={require('src/assets/images/interiors/ensuite_dark.jpg')}
        title={t.interiors.opalOnyx.content2.title}
        body={t.interiors.opalOnyx.content2.body}
      />

      <Features />
    </Root>
  );
};

export default Interiors;

const Root = styled.div``;

const ShowOnMobile = styled.div`
  display: block;
  color: ${({ theme }) => theme.color.navy};
  @media ${media.tablet} {
    display: none;
  }
`;

const Body = styled.div`
  margin: 0 auto;
  ${vw('width', 288, 648, 470)}
  ${vw('margin-top', 40, 40, 0)}
  ${vw('margin-left', 'auto', 'auto', 85)}
  h2 {
    ${vw('margin-bottom', 20, 40)}
  }
`;
