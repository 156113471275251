import React, { useState } from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Button from 'components/Button';
import Modal from '../elements/Modal';

import data from 'src/data/amenities';

import vw from 'src/styles/utils';

const Levels = () => {
  const [activeLevel, setActiveLevel] = useState(1);
  const [activePoint, setActivePoint] = useState(null);

  const { t } = useIntl();

  const handleActiveLevel = (i) => {
    setActiveLevel(i);
  };

  const handlePoint = (i) => {
    setActivePoint(i);
  };

  const handleClose = () => {
    setActivePoint(null);
  };

  return (
    <Root>
      <h2 className="navy">{t.amenities.levels.title}</h2>
      <Buttons>
        <Button
          text={t.amenities.levels.button1}
          ghost={activeLevel !== 0}
          button
          onClick={() => handleActiveLevel(0)}
        />
        <StyledButton
          text={t.amenities.levels.button2}
          ghost={activeLevel !== 1}
          button
          onClick={() => handleActiveLevel(1)}
        />
      </Buttons>

      <Image>
        {data.levels.map((level, i) => (
          <Birdseye
            src={level.birdseye}
            alt=""
            style={{
              opacity: activeLevel === i ? '1' : '0',
            }}
            key={i}
          />
        ))}

        {data.levels[activeLevel].poi.map((poi, i) => (
          <POI
            key={i}
            src={require('src/assets/images/amenities/call_out.png')}
            alt=""
            top={poi.top}
            left={poi.left}
            onClick={() => handlePoint(i)}
          />
        ))}
      </Image>

      <Modal
        activeLevel={activeLevel}
        activePoint={activePoint}
        handleClose={handleClose}
      />
    </Root>
  );
};

export default Levels;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${vw('padding-bottom', 80, 120, 160)}
  h2 {
    align-self: center;
    text-align: center;
    ${vw('margin-bottom', 40, 48, 80)}
  }
`;

const Buttons = styled.div`
  display: flex;
  ${vw('margin-bottom', 40, 48, 80)}
`;

const StyledButton = styled(Button)`
  pointer-events: ${(props) => (props.ghost ? 'auto' : 'none')};
  ${vw('margin-left', 40, 26)}
`;

const Image = styled.div`
  position: relative;
  ${vw('width', 288, 648, 926)}
  ${vw('height', 176.66, 397.48, 568)}
`;

const Birdseye = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.4s ease;
  ${vw('width', 288, 648, 926)}
  ${vw('height', 176.66, 397.48, 568)}
`;

const POI = styled.img`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  transform: translate(-50%, -50%);
  height: auto;
  ${vw('width', 17, 35)}
`;
