import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw, { vwDesktop } from 'src/styles/utils';

const Compass = (props) => {
  const { angle, activePlan } = props;
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    setDirection(angle);
  }, [angle]);

  return (
    <Root>
      <svg
        width="97"
        height="117"
        viewBox="0 0 97 117"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Circle */}
        <path
          opacity="0.745698"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M49.137 115.973C74.5272 115.973 95.11 95.3901 95.11 70C95.11 44.6098 74.5272 24.027 49.137 24.027C23.7469 24.027 3.16406 44.6098 3.16406 70C3.16406 95.3901 23.7469 115.973 49.137 115.973Z"
          stroke="white"
          stroke-width="2"
        />
        <path
          d="M47.5979 9.57829V4.26169L51.8822 9.57829H53.5149V0.821533H51.5508V6.13814L47.2788 0.821533H45.6338V9.57829H47.5979Z"
          fill="white"
        />

        <Direction
          angle={-direction}
          d="M49.2195 21.1809V26.5136C25.0818 26.5136 5.51436 46.0811 5.51436 70.2187H0.181641C0.181641 43.1359 22.1366 21.1809 49.2195 21.1809Z"
          fill="white"
        />
      </svg>

      <Level>
        <p className="uppercase">Level</p>
        <h3>
          {typeof activePlan === 'undefined'
            ? '35'
            : activePlan.viewLevel
            ? activePlan.viewLevel
            : '35'}
        </h3>
      </Level>
    </Root>
  );
};

Compass.propTypes = {
  angle: PropTypes.number,
  activePlan: PropTypes.number,
};

const Root = styled.div`
  z-index: 2;

  pointer-events: none;
  position: absolute;
  right: ${vwDesktop(60)};
  top: ${vwDesktop(64)};

  > img {
    position: absolute;
    top: 0;
    left: 0;
  }

  > svg {
    overflow: initial;
  }
`;

const Direction = styled.path`
  transform: ${(props) => `translate(0px, 0px) rotate(${props.angle + 90}deg)`};
  transform-origin: 50% 60%;
`;

const Level = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  p,
  h3 {
    color: white;
  }
  p {
    ${vw('font-size', 13)}
    ${vw('line-height', 20)}
    ${vw('letter-spacing', 0.3)}
  }
  h3 {
    ${vw('font-size', 24)}
    ${vw('line-height', 28)}
  }
`;

export default Compass;
