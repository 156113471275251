import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Hero from 'components/Layout/Hero';
import MapSection from './sections/MapSection';
import ContentBlock from 'components/Layout/ContentBlock';

import vw from 'src/styles/utils';

const Neighbourhood = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Hero
        title={t.neighbourhood.hero.title}
        src={require('src/assets/images/neighbourhood/hero.jpg')}
        fullStrapline={t.neighbourhood.hero.fullStrapline}
      />
      <MapSection />
      <ContentBlock
        title={t.neighbourhood.content1.title}
        text={t.neighbourhood.content1.text}
        image={require('src/assets/images/neighbourhood/mall.jpg')}
      />
      <ContentBlock
        title={t.neighbourhood.content2.title}
        text={t.neighbourhood.content2.text}
        image={require('src/assets/images/neighbourhood/skytrain.jpg')}
        reverse
      />
      <Space />
    </Root>
  );
};

export default Neighbourhood;

const Root = styled.div``;
const Space = styled.div`
  ${vw('height', 90, 90)}
`;
