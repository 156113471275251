import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import vw from 'src/styles/utils';
import media from 'src/styles/media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: 'Roboto';
    // This is a temporary fix to disable the iframe coming from react-hot-loader in development
    > iframe {
      display: none;
    }
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .navy {
    color: ${theme.color.navy};
  }

  .gold {
    color: ${theme.color.gold};
  }

  .grey {
    color: ${theme.color.grey};
  }

  .lightGold {
    color: ${theme.color.lightGold};
  }

  .italic {
    font-style: italic;
  }

  .uppercase {
    text-transform: uppercase;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  h1 {
    font-family: ${theme.font.header};
    font-weight: 400;
    font-style: normal;
    ${vw('font-size', 26, 60)}
    ${vw('line-height', 30, 68)}
    ${vw('letter-spacing', 0.5, 1.2)}
  }

  h2 {
    font-family: ${theme.font.header};
    font-weight: 400;
    font-style: normal;
    ${vw('font-size', 18, 54)}
    ${vw('line-height', 21, 62)}
    ${vw('letter-spacing', 'normal', 1)}
  }

  h3 {
    font-family: ${theme.font.header};
    font-weight: 400;
    font-style: normal;
    ${vw('font-size', 14, 24)}
    ${vw('line-height', 16, 28)}
    ${vw('letter-spacing', 'normal')}
  }

  p {
    font-family: ${theme.font.family};
    font-weight: 400;
    font-style: normal;
    ${vw('font-size', 13, 14)}
    ${vw('line-height', 22, 26)}
    ${vw('letter-spacing', 0.3, 0.3)}
  }

  .small {
    font-family: ${theme.font.family};
    font-weight: 400;
    font-style: normal;
    ${vw('font-size', 10, 13)}
    ${vw('line-height', 18, 20)}
    ${vw('letter-spacing', 'normal', 0.3)}
  }

  .main_menu {
    font-family: ${theme.font.family};
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all 0.4s ease;
    ${vw('font-size', 14)}
    ${vw('line-height', 17)}
    ${vw('letter-spacing', 2)}

    &.active {
      color: ${theme.color.gold};
    }

    @media ${media.desktop} {
      &:hover {
        /* font-weight: 600; */
        text-decoration-color: ${theme.color.gold};
      }
    }
  }

  .button_label {
    font-family: ${theme.font.family};
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    transition: all 0.4s ease;
    ${vw('font-size', 11, 14)}
    ${vw('line-height', 'normal', 17)}
    ${vw('letter-spacing', 1, 2)}

    &:hover {
      /* font-weight: 400; */
    }
  }
`;
