import React, { useState } from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import { View360 } from 'bam-storybook';
import Compass from '../elements/Compass';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

// let clientX, deltaX, angle;

let defaultAngle = 45;
// let offset = -135;
// let speed = 0.1;

const Views = (props) => {
  const { activePlan } = props;
  // const [activeLevelIndex, setActiveLevelIndex] = useState(0);
  const [angle, setAngle] = useState(defaultAngle);
  const [isShowSwipeToPanImg, setIsShowSwipeToPanImg] = useState(true);

  const { t } = useIntl();

  // const calculateAngle = (nowPositionX) => {
  //   var image = new Image();
  //   // getUpdateAngle(360*nowPositionX/image.width%360+offset)
  // };

  const onViewUpdate = (angle) => {
    setAngle(angle);
  };

  return (
    <Root onClick={() => setIsShowSwipeToPanImg(false)}>
      <Title>{t.floorplans.views.title}</Title>
      <Compass angle={angle} activePlan={activePlan} />
      <SwipeToPan show={isShowSwipeToPanImg}>
        {t.floorplans.views.drag}
      </SwipeToPan>
      <View360
        src={
          typeof activePlan === 'undefined'
            ? require(`src/assets/images/floorplans/views/Riviera_Level_35.jpg`)
            : require(`src/assets/images/floorplans/views/Riviera_Level_${
                activePlan.viewLevel ? activePlan.viewLevel : '35'
              }.jpg`)
        }
        defaultAngle={angle}
        onUpdate={onViewUpdate}
        onStart={() => setIsShowSwipeToPanImg(false)}
        onEnd={() => setIsShowSwipeToPanImg(true)}
      />
      <Overlay />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  ${vw('height', 380, 640)};
  position: relative;
`;

const Title = styled.h1`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  pointer-events: none;
  color: white;
  text-align: left;
  ${vw('font-size', 22, 38)};
  ${vw('line-height', 28, 42)};

  @media ${media.tablet} {
    top: 60px;
    left: 60px;
  }

  @media ${media.desktop} {
    left: 90px;
  }
`;

const SwipeToPan = styled.h3`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: ${(props) => (props.show ? '1' : '0')};
  color: white;
  pointer-events: none;
  transition: all 0.3s ease-out;
`;

const Overlay = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.2;
`;

export default Views;
