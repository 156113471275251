import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Gallery = (props) => {
  const { data, portrait } = props;

  const [activeImage, setActiveImage] = useState(0);

  const swiping = useSwipeable({
    onSwipedLeft: (e) => handleSwipe(e),
    onSwipedRight: (e) => handleSwipe(e),
  });

  const handleNext = useCallback(() => {
    if (data.length - 1 === activeImage) {
      setActiveImage(0);
    } else {
      setActiveImage(activeImage + 1);
    }
  }, [activeImage, data.length]);

  useEffect(() => {
    const interval = setInterval(() => handleNext(), 2500);
    return () => clearInterval(interval);
  }, [handleNext]);

  const handlePrev = () => {
    if (activeImage === 0) {
      setActiveImage(data.length - 1);
    } else {
      setActiveImage(activeImage - 1);
    }
  };

  const handleSwipe = (e) => {
    if (e.dir.toLowerCase() === 'right') {
      handlePrev();
    } else {
      handleNext();
    }
  };

  return (
    <Root portrait={portrait} {...swiping}>
      {data.map((image, index) => (
        <Image
          src={image.src}
          alt={image.alt}
          key={index}
          style={{
            opacity: activeImage === index ? 1 : 0,
            pointerEvents: activeImage === index ? 'all' : 'none',
          }}
        />
      ))}
      <Arrow
        src={require('src/assets/images/_global/icons/arrow_left.svg')}
        alt="Previous"
        onClick={handlePrev}
      />
      <Arrow
        src={require('src/assets/images/_global/icons/arrow_right.svg')}
        alt="Next"
        onClick={handleNext}
        right
      />
      <Pagination>
        {activeImage + 1} / {data.length}
      </Pagination>
    </Root>
  );
};

Gallery.propTypes = {
  data: PropTypes.array,
  portrait: PropTypes.bool,
};

const Root = styled.div`
  width: 100%;
  height: ${props => props.portrait ? vwMobile(328) : vwMobile(192)};
  position: relative;
  @media ${media.tablet} {
    height: ${props => props.portrait ? vwTablet(736) : vwTablet(432)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(600)};
    height: ${props => props.portrait ? vwDesktop(680) : vwDesktop(400)};
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.4s ease;
`;

const Arrow = styled.img`
  display: block;
  height: auto;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  ${vw('bottom', 20, 28)}
  ${vw('width', 8, 12)};

  right: ${(props) => props.right ? '38%' : 'unset'};
  left: ${(props) => props.right ? 'unset' : '38%'};
`;

const Pagination = styled.div`
  background-color: white;
  ${vw('width', 30, 46)}
  ${vw('height', 30, 46)}
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 50%;
  color: ${({ theme }) => theme.color.blue};
  font-weight: 600;
  ${vw('font-size', 8, 11)}
  ${vw('bottom', 20, 28)}
`;

export default Gallery;
