export default {
  color: {
    navy: '#00263A',
    gold: '#B7A165',
    grey: '#DDDDDD',
    lightGold: '#E4E2DB',
  },
  font: {
    header: 'Baskerville',
    family: 'Montserrat',
  }
};
