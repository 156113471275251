// Packages
import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';
import PropTypes from 'prop-types';
import ReactCompareImage from 'react-compare-image';

import vw, { vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const OpalOnyx = (props) => {
  const { opal, onyx } = props;

  const { t } = useIntl();
  return (
    <Root>
      {/* Desktop & Tablet */}
      <CompareWrapper>
        <ReactCompareImage
          leftImage={opal}
          rightImage={onyx}
          handle={
            <Drag
              src={require('src/assets/images/interiors/drag_icon.svg')}
              alt="drag"
            />
          }
          handleSize={58}
          sliderLineColor={theme.color.navy}
          sliderLineWidth={7}
          sliderPositionPercentage={0.5}
        />
      </CompareWrapper>

      <Container>
        <Image width="288" src={opal} />

        <SchemeLabel left className="button_label">
          {t.interiors.opal}
        </SchemeLabel>

        <Image width="288" src={onyx} />

        <SchemeLabel right className="button_label">
          {t.interiors.onyx}
        </SchemeLabel>
      </Container>
    </Root>
  );
};

export default OpalOnyx;

OpalOnyx.propTypes = {
  opal: PropTypes.string,
  onyx: PropTypes.string,
};

const Root = styled.div`
  position: relative;
`;

const CompareWrapper = styled.div`
  display: none;

  @media ${media.tablet} {
    display: unset;
  }
`;

const Drag = styled.img`
  /* ${vw('width', 58)} */
`;

const Container = styled.div`
  width: 100%;
  ${vw('padding-right', 0, 0, 20)}
  ${vw('padding-left', 0, 0, 20)}
  ${vw('bottom', 0, 0, 20)}
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
  @media ${media.desktop} {
    position: absolute;
  }
`;

const Image = styled.img`
  display: block;
  width: ${vwMobile(288)};
  margin: auto;
  margin-top: ${vwMobile(40)};

  @media ${media.tablet} {
    display: none;
  }
`;

const SchemeLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: start;
  color: ${theme.color.navy};
  background-color: white;
  ${vw('width', 'auto', 'auto', 78)}
  ${vw('height', 35)}

  @media ${media.desktop} {
    justify-content: center;
  }
`;
