import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const Input = (props) => {
  const { label, name, id, required, type } = props;
  return (
    <Root>
      <label htmlFor={id} className="form label">
        <p>{label}</p>
      </label>
      <input
        type={type}
        name={name}
        id={id}
        required={required}
        className="form input"
      />
    </Root>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
};

const Root = styled.div`
  width: 100%;
  ${vw('margin-bottom', 20, 24)}
  label {
    display: block;
    color: ${({ theme }) => theme.color.navy};
    ${vw('margin-bottom', 4)}
  }
  input {
    appearance: none;
    width: 100%;
    border-radius: 0;
    border: 1px solid ${theme.color.blue};
    transition: ${({ theme }) => theme.transition};
    background-color: transparent;
    color: ${({ theme }) => theme.color.navy};
    ${vw('height', 36, 40)}
    ${vw('padding-top', 9, 10)}
    ${vw('padding-right', 12)}
    ${vw('padding-left', 12)}
    ${vw('padding-bottom', 9, 10)}
    &:focus {
      outline: none;
      background-color: white;
    }
  }
`;

export default Input;
