export default [
  {
    id: 'ibi',
    name: 'Arcadis | IBI Group',
    strapline: 'Architecture',
    straplineKO: '설계',
    logo: 'Arcadis_Logo.png',
    logoHeightDesktop: 40,
    logoHeightMobile: 32,
    link: '/',
  },
  {
    id: 'themill',
    name: 'The Mill Design Group',
    strapline: 'Interior Design',
    straplineKO: '인테리어 디자인',
    logo: 'logo_themill.png',
    logoHeightDesktop: 47,
    logoHeightMobile: 50,
    link: '/',
  },
  {
    id: 'pwl',
    name: 'PWL Parnership',
    strapline: 'Lanscape  Architect',
    straplineKO: '조경 건축',
    logo: 'logo_pwl.png',
    logoHeightDesktop: 77,
    logoHeightMobile: 50,
    link: '/',
  },
];
