import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Button = (props) => {
  const {
    text,
    to,
    href,
    button,
    handleClick,
    gold,
    invertedGold,
    ghost,
    disabled,
    type,
  } = props;

  return (
    <Root {...props}>
      {to && (
        <StyledLink
          to={to}
          onClick={handleClick ? () => handleClick() : () => {}}
          gold={gold}
          invertedGold={invertedGold}
          ghost={ghost}
          disabled={disabled}
          className="button_label"
        >
          {text}
        </StyledLink>
      )}

      {href && (
        <A
          href={href}
          onClick={handleClick ? () => handleClick() : () => {}}
          gold={gold}
          invertedGold={invertedGold}
          ghost={ghost}
          disabled={disabled}
          className="button_label"
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </A>
      )}

      {button && (
        <Btn
          onClick={handleClick ? () => handleClick() : () => {}}
          gold={gold}
          invertedGold={invertedGold}
          ghost={ghost}
          disabled={disabled}
          className="button_label"
          type={type}
        >
          {text}
        </Btn>
      )}
    </Root>
  );
};

export default Button;

Button.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  button: PropTypes.bool,
  handleClick: PropTypes.func,
  gold: PropTypes.bool,
  invertedGold: PropTypes.bool,
  ghost: PropTypes.bool,
  disabled: PropTypes.bool,
};

const ButtonStyles = css`
  cursor: pointer;
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.navy};
  border: 1px solid ${({ theme }) => theme.color.navy};
  color: white;
  transition: all 0.4s ease;
  ${vw('height', 25, 37)}
  ${vw('padding-right', 18, 32)}
  ${vw('padding-left', 18, 32)}

  @media ${media.desktop} {
    &:hover {
      background-color: ${({ theme }) => theme.color.gold};
      border: 1px solid ${({ theme }) => theme.color.gold};
      color: ${({ theme }) => theme.color.navy};
    }
  }
`;

const Gold = css`
  background-color: ${({ theme }) => theme.color.gold};
  border: 1px solid ${({ theme }) => theme.color.gold};
  color: ${({ theme }) => theme.color.navy};

  @media ${media.desktop} {
    &:hover {
      border: 1px solid white;
      background-color: white;
      color: ${({ theme }) => theme.color.navy};
    }
  }
`;

const InvertedGold = css`
  background-color: ${({ theme }) => theme.color.lightGold};
  border: 1px solid ${({ theme }) => theme.color.lightGold};
  color: ${({ theme }) => theme.color.navy};
`;

const Ghost = css`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.navy};
  color: ${({ theme }) => theme.color.navy};
`;

const Disabled = css``;

const Root = styled.div`
  display: inline-block;
`;

const StyledLink = styled(Link)`
  ${ButtonStyles}

  ${(props) => props.gold && Gold}
  ${(props) => props.invertedGold && InvertedGold}
  ${(props) => props.ghost && Ghost}
  ${(props) => props.disabled && Disabled}
`;

const A = styled.a`
  ${ButtonStyles}

  ${(props) => props.gold && Gold}
  ${(props) => props.invertedGold && InvertedGold}
  ${(props) => props.ghost && Ghost}
  ${(props) => props.disabled && Disabled}
`;

const Btn = styled.button`
  ${ButtonStyles}

  ${(props) => props.gold && Gold}
  ${(props) => props.invertedGold && InvertedGold}
  ${(props) => props.ghost && Ghost}
  ${(props) => props.disabled && Disabled}
`;
