import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Title from 'components/Layout/Title';
import Button from 'components/Button';

import vw from 'src/styles/utils';

const NotFound = () => {
  const { t, getLocaleURL } = useIntl();

  return (
    <Root>
      <Title text={t.notFound.title} />
      <h4>{t.notFound.strapline}</h4>
      <p>{t.notFound.body}</p>
      <Button text={t.notFound.button} to={`${getLocaleURL()}/`} />
    </Root>
  );
};

export default NotFound;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-bottom', 80, 120, 160)}
  h4 {
    font-family: ${({ theme }) => theme.font.family};
    font-weight: 400;
    text-transform: uppercase;
    ${vw('font-size', 14)}
    ${vw('line-height', 22)}
    ${vw('letter-spacing', 3)}
    ${vw('margin-top', 24)}
    ${vw('margin-bottom', 40)}
  }
  p {
    text-align: center;
    ${vw('margin-bottom', 40)}
  }
`;
