import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'src/components/Button';
import Gallery from 'components/Gallery';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const ContentBlock = (props) => {
  const {
    title,
    text,
    intro,
    body,
    image,
    gallery,
    video,
    reverse,
    vertical,
    portrait,
    home,
  } = props;

  return (
    <Root className="navy" reverse={reverse} vertical={vertical}>
      {image && home ? (
        <ImageWrapper>
          <Image src={image} reverse={reverse} />
          <Frame reverse={reverse && 1} />
        </ImageWrapper>
      ) : image && !home ? (
        <NeighbourhoodImage src={image} reverse={reverse} />
      ) : null}
      {video && (
        <Video src={video} autoPlay muted playsInline loop reverse={reverse} />
      )}
      {gallery && (
        <GalleryWrapper reverse={reverse}>
          <Gallery data={gallery} portrait={portrait} />
        </GalleryWrapper>
      )}
      <Wrapper vertical={vertical} reverse={reverse}>
        <InnerWrapper>
          <Title sun={title === 'Under the Sun and Stars' && 1}>{title}</Title>

          {text && <Body className="body">{text}</Body>}

          {intro && <Body className="intro">{intro}</Body>}

          {body && <BodyList>{body}</BodyList>}
          {props.buttonLabel && (
            <StyledButton
              to={props.buttonTo}
              text={props.buttonLabel}
              className="button"
            />
          )}
        </InnerWrapper>
      </Wrapper>
    </Root>
  );
};

ContentBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  intro: PropTypes.string,
  image: PropTypes.string,
  video: PropTypes.string,
  reverse: PropTypes.bool,
  vertical: PropTypes.bool,
  gallery: PropTypes.array,
  buttonLabel: PropTypes.string,
  buttonTo: PropTypes.string,
  portrait: PropTypes.bool,
};

const Root = styled.div`
  ${vw('padding-top', 40, 60, 80)}
  ${vw('padding-bottom', 40, 60, 80)}
  ${vw('padding-left', 16, 0)}
  ${vw('padding-right', 16, 0)}
  @media ${media.tablet} {
    margin: 0 ${vwTablet(60)};
  }

  @media ${media.desktop} {
    display: ${(props) => (props.vertical ? 'block' : 'flex')};
    align-items: center;
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    justify-content: space-between;
    margin-top: 0;
    margin-right: ${(props) => (props.reverse ? vwDesktop(80) : vwDesktop(80))};
    margin-left: ${(props) => (props.reverse ? vwDesktop(189) : vwDesktop(80))};
  }
`;

const GalleryWrapper = styled.div`
  @media ${media.desktop} {
    /* order: ${(props) => (props.reverse ? 1 : 2)}; */
  }
`;

const Wrapper = styled.div`
  margin-top: ${vwMobile(40)};
  @media ${media.tablet} {
    margin-top: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    width: ${(props) => (props.vertical ? '100%' : vwDesktop(410))};
    margin-top: ${(props) => (props.vertical ? vwDesktop(60) : 0)};
    margin-right: ${(props) =>
      props.vertical || props.reverse ? 0 : vwDesktop(140)};
    padding: ${(props) => (props.vertical ? `0 ${vwDesktop(160)}` : '0')};
    display: flex;
    flex-direction: column;
    align-items: center;
    order: ${(props) => (props.reverse ? 2 : 1)};
  }
`;

const InnerWrapper = styled.div`
  @media ${media.desktop} {
    width: ${(props) => (props.vertical ? '100%' : vwDesktop(420))};
  }
`;

const Title = styled.h2`
  margin-bottom: ${vwTablet(20)};
  ${vw('font-size', 26, 60)}
  ${vw('line-height', 30, 68)}
  font-weight: 400;
  color: ${(props) => props.theme.color.navy};

  @media ${media.tablet} {
    max-width: ${vwTablet(415)};
    margin-bottom: ${vwTablet(40)};
  }

  @media ${media.desktop} {
    max-width: ${(props) => (props.sun ? vwDesktop(400) : vwDesktop(500))};
    margin-bottom: ${(props) => (props.vertical ? 0 : vwDesktop(40))};
  }
`;

const Body = styled.p`
  @media ${media.desktop} {
    width: ${vwDesktop(380)};
  }
`;

const BodyList = styled.div`
  position: relative;
  list-style: none;
  text-align: left;

  @media ${media.desktop} {
    width: ${vwDesktop(340)};
    margin-left: 0;
    margin-top: 0;
  }

  p {
    color: #444444;
    ${vw('padding-top', 10, 18)}
    ${vw('padding-bottom', 10, 18)}
    border-bottom: 1px solid ${theme.color.blue};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border: none;
      padding-bottom: 0;
    }

    @media ${media.desktop} {
      width: ${vwDesktop(340)};
    }
  }
`;

const StyledButton = styled(Button)`
  ${vw('margin-top', 20, 40)};
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 0;
`;

const Frame = styled.div`
  display: none;
  border: 1px solid ${(props) => props.theme.color.gold};
  position: absolute;
  bottom: ${vw(-40)};
  ${(props) => (props.reverse ? vw('right', -24) : vw('left', -24))}
  ${vw('top', -24)}
  ${vw('width', 288, 648, 600)}
  ${vw('height', 192, 432, 400)}
  z-index: 1;
  @media ${media.desktop} {
    display: block;
  }
`;

const Image = styled.img`
  ${vw('width', 288, 648, 600)}
  ${vw('height', 192, 432, 400)}
  /* display: block; */
  position: relative;
  z-index: 2;

  @media ${media.desktop} {
    width: ${vwDesktop(624)};
  }
`;

const NeighbourhoodImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  @media ${media.desktop} {
    width: ${vwDesktop(624)};
  }
`;

const Video = styled.video`
  width: 100%;
  ${vw('height', 220, 498, 480)}
  object-fit: cover;
  display: block;

  @media ${media.desktop} {
    width: ${vwDesktop(624)};
  }
`;

export default ContentBlock;
