export default {
  levels: [
    {
      text: 'Level 1',
      birdseye: require('src/assets/images/amenities/level_1_birdseye.png'),
      poi: [
        {
          title: 'Concierge Desk',
          top: '47.5%',
          left: '38%',
          src: require('src/assets/images/amenities/lightbox/amenity_lobby-concierge.jpg'),
        },
        {
          title: 'Lobby Fireplace',
          top: '53%',
          left: '42.5%',
          src: require('src/assets/images/amenities/lightbox/amenity_lobby_fireplace.jpg'),
        },
        {
          title: 'Lobby Terrace',
          top: '28%',
          left: '63.5%',
          src: require('src/assets/images/amenities/lightbox/amenity_rooftop.jpg'),
        },
      ],
    },
    {
      text: 'Level 7',
      birdseye: require('src/assets/images/amenities/level_7_birdseye.png'),
      poi: [
        {
          title: 'Dining Area',
          top: '32%',
          left: '21.5%',
          src: require('src/assets/images/amenities/lightbox/amenity_kitchen.jpg'),
        },
        {
          title: 'Ping Pong Table',
          top: '34%',
          left: '46%',
          src: require('src/assets/images/amenities/lightbox/amenity_ping-pong.jpg'),
        },
        {
          title: 'Terrace Lounge',
          top: '43%',
          left: '57.5%',
          src: require('src/assets/images/amenities/lightbox/amenity_exterior.jpg'),
        },
      ],
    },
  ],
};
