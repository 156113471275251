import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Hero from './sections/Hero';
import ContentBlock from 'components/Layout/ContentBlock';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Home = () => {
  const { t, getLocaleURL } = useIntl();

  return (
    <Root>
      <Wrapper>
        <h1>{t.home.header}</h1>
        <Hero />
      </Wrapper>

      <Content>
        <ContentBlock
          title={t.home.content1.title}
          text={t.home.content1.text}
          image={require('src/assets/images/home/interiors_panel.jpg')}
          buttonLabel={t.home.content1.button}
          buttonTo={`${getLocaleURL()}/interiors`}
          home
        />
        <ContentBlock
          title={t.home.content2.title}
          text={t.home.content2.text}
          image={require('src/assets/images/home/amenities_panel.jpg')}
          buttonLabel={t.home.content2.button}
          buttonTo={`${getLocaleURL()}/amenities`}
          reverse
          home
        />
        <ContentBlock
          title={t.home.content3.title}
          text={t.home.content3.text}
          image={require('src/assets/images/home/neighbourhood_panel.jpg')}
          buttonLabel={t.home.content3.button}
          buttonTo={`${getLocaleURL()}/neighbourhood`}
          home
        />
      </Content>
    </Root>
  );
};

export default Home;

const Root = styled.div``;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.navy};
  ${vw('padding-top', 133, 216, 223)}

  h1 {
    color: white;
    margin: 0 auto;
    text-align: center;
  }

  .tablet {
    display: none;
  }

  @media ${media.tablet} {
    .mobile {
      display: none;
    }
    .tablet {
      display: block;
    }
  }
`;

const Content = styled.div`
  ${vw('padding-top', 0, 60, 80)}
  ${vw('padding-bottom', 0, 60, 80)}
`;
